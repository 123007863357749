import {appendQueryString} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IOSMPoisRoutesGrouped} from "../actions/fetch_osm_pois_routes_grouped";
import {IPoi} from "../types/IPoi";
import {PoiType, TransportPoiType} from "../utils/PoiType";

export interface IGetOfferDetailPoiResponse {
    id: number;
    poi: {
        pois: Record<Exclude<PoiType, PoiType.TRANSPORT>, IPoi[]> & Record<PoiType.TRANSPORT, Record<TransportPoiType, IPoi[]>>;
        routes_grouped: IOSMPoisRoutesGrouped | null;
    } | null;
}
interface IGetPoiByType {
    offerId: number;
    poiTypes?: PoiType[];
}

export const getOfferDetailPoi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getOfferDetailTransportPoi: build.query<IGetOfferDetailPoiResponse, number>({
            query: (offerId) => ({
                url: appendQueryString(apiV2Link.offer.detail(Scenario.OFFER_DETAIL_POIS, {offerId}), {poi_type: PoiType.TRANSPORT}),
                offerId
            })
        }),
        getOfferDetailPoiByType: build.query<IGetOfferDetailPoiResponse, IGetPoiByType>({
            query: ({offerId, poiTypes}) => ({
                url: appendQueryString(apiV2Link.offer.detail(Scenario.OFFER_DETAIL_POIS, {offerId}), {poi_type: poiTypes}),
                offerId,
                poiTypes
            })
        })
    })
});

export const {useGetOfferDetailTransportPoiQuery, useGetOfferDetailPoiByTypeQuery, useLazyGetOfferDetailPoiByTypeQuery} = getOfferDetailPoi;
